import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "./footer"
import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="container-fluid">
      <Header siteTitle={data.site.siteMetadata.title} />

      <main className="row">
        {children}
        <Footer />
      </main>
      <footer className="row mt-5">
        <div className="col-lg-12 p-0">
          <div
            className="d-flex flex-row pl-5 align-items-center justify-content-center"
            style={{ height: 65, backgroundColor: "#33af5b", color: "white" }}
          >
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://yet.marketing">yet.marketing</a>
          </div>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
