import React, { useEffect, useState } from "react"
import Carousel from "nuka-carousel"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

export default () => {
  const data = useStaticQuery(graphql`
    query Projects {
      allFile: allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "projects" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      arrowRight: file(relativePath: { eq: "arrow-right.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [responsive, setResponsive] = useState()
  useEffect(() => {
    if (typeof window != "undefined") {
      window.addEventListener("resize", data => {
        if (data.currentTarget.innerWidth < 992) {
          setResponsive(true)
        }
      })
    }
  }, [])

  return (
    <>
      <div
        className="w-100 h-100 absolute background_white"
        style={{ zIndex: -1 }}
      ></div>
      <h3 className=" text-center mb-5 pt-5 mt-5">Nos projets</h3>
      <Carousel
        slidesToScroll={1}
        slidesToShow={responsive ? 1 : 4}
        slidesToScroll={1}
        autoplay={true}
        transitionMode="fade"
        withoutControls={true}
        opacityScale={0.4}
        wrapAround={true}
      >
        {data.allFile.edges.map(edge => {
          return (
            <div style={{ height: 300, width: "100%" }}>
              <BackgroundImage
                style={{ height: 300, width: "100%" }}
                Tag="section"
                fluid={edge.node.childImageSharp.fluid}
              ></BackgroundImage>
            </div>
          )
        })}
      </Carousel>
      <button className="actionButton d-flex align-items-center justify-content-between flex-row ml-auto mr-auto mt-4">
        <Link to="/references">
          <span className="mr-3">voir plus</span>
        </Link>

        <div
          style={{
            backgroundColor: "white",
            borderRadius: "100%",
            width: 18,
            height: 18,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Img
            style={{ width: 13, height: 13 }}
            fluid={data.arrowRight.childImageSharp.fluid}
          />
        </div>
      </button>
    </>
  )
}
