import React from "react"
import Img from "gatsby-image"
import useImageQuery from "@/components/index/images"

export default () => {
  return (
    <>
      <div className="w-100 h-100 absolute background_white"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className=" mt-5 text-center">
              <h3 className="m-0 mb-5 mt-5 pt-5">Nos services</h3>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="row">
              <Service
                image={useImageQuery().terrasses.childImageSharp.fluid}
                texte="Conceptions de Terrasses et Cours pour les particuliers et professionnels;"
              />
              <Service
                image={useImageQuery().garden.childImageSharp.fluid}
                texte="Conceptions de Jardins personnalisés et Aménagements de Paysages;"
              />
              <Service
                image={useImageQuery().pointdeau.childImageSharp.fluid}
                texte="Conceptions et Entretiens des Etangs et Pièces d'eau"
              />
              <Service
                image={useImageQuery().parterre.childImageSharp.fluid}
                texte="Prestation de Tonte de Pelouse et Pulvérisations des Allées;"
              />
              <Service
                image={useImageQuery().arbustes.childImageSharp.fluid}
                texte="Prestations de Tailles de Haies, d'Arbustes, d'Arbres Fruitiers et d'Ornement et Elagages et Abattages d'Arbres;"
              />
              <Service
                image={useImageQuery().cosy.childImageSharp.fluid}
                texte="Prestations d'Entretien des Arbustes et des Espaces Verts;"
              />
              <Service
                image={useImageQuery().pose.childImageSharp.fluid}
                texte="Conception de Paysages - Jardins Cosy et d'Allées de Jardins;"
              />
              <Service
                image={useImageQuery().beton.childImageSharp.fluid}
                texte="Beton imprimé"
              />
              <Service
                image={useImageQuery().atalus.childImageSharp.fluid}
                texte="Atalus"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Service = ({ image, texte }) => {
  return (
    <div className="col-lg-4 mb-4">
      <div
        className="relative w-100"
        style={{
          boxShadow: "0px 0px 2px -1px #000",
        }}
      >
        <Img fluid={image} />
        <h4 className="p-2">{texte}</h4>
      </div>
    </div>
  )
}
