import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Logo from "./logo"
import styles from "./styles.module.css"

const MenuItem = ({ label, link }) => {
  return (
    <Link
      to={link}
      activeClassName={styles.active}
      className="h-100 white d-flex align-items-center justify-content-center mr-3"
      style={{
        color: "#f4f4f4",
        fontWeight: "bolder",
        fontSize: 18,
      }}
    >
      <li
        className="d-flex justify-content-center d-flex align-items-center m-0 uppercase"
        style={{ textShadow: "0px 1px 1px #000" }}
      >
        {label}
      </li>
    </Link>
  )
}

const Header = ({ siteTitle }) => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <header className="row w-100">
      <div className="col-lg-12 d-flex align-items-center justify-content-between">
        <div className={`${styles.header} absolute left w-100 top`}>
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 d-flex flex-row align-items-center">
                <Logo />

                <ul
                  className={`${
                    showMenu ? "d-flex responsiveMenu" : "d-lg-flex d-none"
                  } align-items-center font-16 white h-100 w-100 m-0 flex-lg-row flex-column`}
                >
                  <MenuItem label="Accueil" link="/" />
                  <MenuItem label="Services" link="/services" />
                  <MenuItem label="Références" link="/references" />
                  <MenuItem label="Contact" link="/contact" />
                  <div className="flex-1"></div>
                </ul>

                <div className="d-lg-none d-flex flex-1"></div>
                <button
                  className="d-lg-none d-flex actionButton"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  Menu
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
