import React from "react"
import Projecs from "@/components/projects"
import References from "@/components/references"
import { Link } from "gatsby"
export default () => {
  return (
    <>
      <div className="col-lg-12 p-0">
        <div
          className="p-5 d-flex flex-column justify-content-center align-items-center"
          style={{
            backgroundColor: "#33af5b",
            color: "white",
          }}
        >
          <h5 style={{ width: "80%", fontWeight: 100, fontSize: 24 }}>
            L'Entreprise TAHTANE MALEK votre Partenaire sûr, pour vous
            accompagner dans la réalisation et l'entretien de vos Espaces verts.
            Contactez nous pour tous vos travaux et conseils d'Aménagements des
            espaces extérieurs de votre structure: Entreprise, Hôpital,
            Clinique, Hôtel, Cours de sport...ou votre maison.
          </h5>
          <Link to="contact">
            <button className="whiteActionButton mt-3">contactez nous</button>
          </Link>
        </div>
      </div>
      <div className="col-lg-12 p-0 mb-5 pb-5">
        <Projecs />
      </div>{" "}
      <div className="col-lg-12 p-0 mt-5">
        <References />
      </div>
    </>
  )
}
