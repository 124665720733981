import React, { useEffect, useState } from "react"
import Carousel from "nuka-carousel"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query References {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "references" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const [responsive, setResponsive] = useState()
  useEffect(() => {
    if (typeof window != "undefined") {
      window.addEventListener("resize", data => {
        if (data.currentTarget.innerWidth < 992) {
          setResponsive(true)
        }
      })
    }
  }, [])

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h3 className="text-center mb-5">Nos Références</h3>
          <Carousel
            slidesToScroll={1}
            slidesToShow={responsive ? 1 : 3}
            slidesToScroll={1}
            transitionMode="scroll"
            wrapAround={true}
            cellAlign="center"
            defaultControlsConfig={{
              nextButtonText: ">",
              prevButtonText: "<",
              nextButtonStyle: {
                height: 50,
                width: 50,
                color: "#019a22",
                backgroundColor: "white",
              },
              prevButtonStyle: {
                height: 50,
                width: 50,
                color: "#019a22",
                backgroundColor: "white",
              },
            }}
          >
            {data.allFile.edges.map(edge => {
              return (
                <div
                  style={{
                    height: 250,
                    width: "100%",
                  }}
                  className="d-flex align-items-center justify-content-center mb-5"
                >
                  <div
                    style={{
                      height: 245,
                      width: 250,
                      backgroundColor: "white",
                      boxShadow: "0px 0px 2px -1px #000",
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Img
                      style={{
                        width: 100,
                      }}
                      fluid={edge.node.childImageSharp.fluid}
                    />
                  </div>
                </div>
              )
            })}
          </Carousel>
        </div>
      </div>
    </div>
  )
}
